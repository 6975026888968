import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/macOS/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "macOS Software for your INSTAR IP Camera",
  "path": "/Software/macOS/SecuritySpy/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "The macOS app SecuritySpy for your iMac or macBook. Control your camera from your computer and harness the computing power for advanced features.",
  "image": "./P_SearchThumb_SecuritySpy.png",
  "social": "/images/Search/P_SearchThumb_SecuritySpy.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-macOS_Security_Spy_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='macOS Software for your INSTAR IP Camera' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='The macOS app SecuritySpy for your iMac or macBook. Control your camera from your computer and harness the computing power for advanced features.' image='/images/Search/P_SearchThumb_SecuritySpy.png' twitter='/images/Search/P_SearchThumb_SecuritySpy.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/macOS/SecuritySpy/' locationFR='/fr/Software/macOS/SecuritySpy/' crumbLabel="SecuritySpy" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "macos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#macos",
        "aria-label": "macos permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`macOS`}</h2>
    <h3 {...{
      "id": "securityspy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#securityspy",
        "aria-label": "securityspy permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SecuritySpy`}</h3>
    <p>{`SecuritySpy ist a IP Surveillance Solution Software for MacOSX from the company `}<a href="http://www.bensoftware.com/securityspy/" target="_blank" rel="noopener noreferrer">{`Ben Software`}</a>{`. In order to integrate your INSTAR IP camera in SecuritySpy we recommend you to download the latest version from `}<a href="http://www.bensoftware.com/securityspy/download.html" target="_blank" rel="noopener noreferrer">{`here`}</a>{`.`}</p>
    <h3 {...{
      "id": "add-a-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-camera",
        "aria-label": "add a camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a Camera`}</h3>
    <p>{`After you installed the newest version of Evocam please start the program, then open the preferences window.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5ec20b468627c34811a9e0406177323f/5a190/SecuritySpy_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsTAAALEwEAmpwYAAABkElEQVQoz2PI7Kiv7+2s7e+s7e+q6m+duvLY8u3PVu14OHft3TkbXy3f/rRh6vTKCR1VE7urJnTWTOyqndhVPbGrfkJ3/sQ2BhYpEQYGBgZGRiYmJgYGhtbWzrMXbuw9cPzoiXM37zy+efuhtJQ0WJ6RAQ7AbHYJIQZWGXEQl5mZmYWFgYFh2rSpL188v3D+/PNnT//////t+1cFRQUGBgZmFmZGJiYoYmZmYGBglRZlYJcUhxgGMTs1NXX2rNkTJkxYtmzZmjVr5s6dKyoqitVmVnEhBlYJUTifWAB1tjADl7gksmYmJiZmGGABA0ZMcyE2iwkxCEjJoruKOJuZRQQY+MSlcWnmAANcmtlEhRj4JWTI08wiLMjAL0mms9lFhRn4JeXJ08wpAtKsQJ5mLmFhBn4JcjULwTUzMYH0MxIGIDXgXMAtIsrAKypDXgrjEhRhULLwElczkta2kFA3FlbSl9Yyk9I0k9Iyl9W1ktWzlNG1ktGxlNO3lte3UTKyk9W1ElMzktExE1fTkzawBgDdrF0iY/IfHwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5ec20b468627c34811a9e0406177323f/e4706/SecuritySpy_01.avif 230w", "/en/static/5ec20b468627c34811a9e0406177323f/d1af7/SecuritySpy_01.avif 460w", "/en/static/5ec20b468627c34811a9e0406177323f/c9875/SecuritySpy_01.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5ec20b468627c34811a9e0406177323f/a0b58/SecuritySpy_01.webp 230w", "/en/static/5ec20b468627c34811a9e0406177323f/bc10c/SecuritySpy_01.webp 460w", "/en/static/5ec20b468627c34811a9e0406177323f/d00b9/SecuritySpy_01.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5ec20b468627c34811a9e0406177323f/81c8e/SecuritySpy_01.png 230w", "/en/static/5ec20b468627c34811a9e0406177323f/08a84/SecuritySpy_01.png 460w", "/en/static/5ec20b468627c34811a9e0406177323f/5a190/SecuritySpy_01.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5ec20b468627c34811a9e0406177323f/5a190/SecuritySpy_01.png",
              "alt": "SecuritySpy for your INSTAR IP Camera",
              "title": "SecuritySpy for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go to Settings and open the Camera Settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a6f5f0f47592effbd03525ecb0df8da9/5a190/SecuritySpy_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC3UlEQVQoz22T70sTcRzHP+rmz82K3BwmLTWhOUTnhJ6k6d/Tg+hRpkkRaZlmuqbzSUGZGKIihRIFbWoquVtr3u5ud97te7sf3m6h6Dah2pO4QVOzD98nX/i8Pq8v7w9fuNl37+5AX/dgf9fAw/vupxOzbzwzkxMzU2PTr0cmX7inX3lmpjyz0+63k+Mzk09eeu4M9nUN9fcMPbox/ADyDCUAkJ+fDwAtzY7NtY2vaxvrq1+8Hz99WFxamJtfmJtfevd+1esjt/De7h6tuaAAAIqrTaA7W54HoNPrAeB6R0dcVaUdWRAFluP4WIykSIIkCIqMq+rvTMY9PgYABYX6PAD9RTPojAbtnh3W1tYmCgKKIo7j8C2cYzlVVQVR5Lgoy7Kp5IFrdDRnLrxgggJDWQ5ub28XBIEIEwihrVAIwwLM9jZJUaFQiKKog2R6+NkRXFJthkKj8TgcjUaDwaAoipIkiaLI87wsyzs7O6IgyIm9x0MjR+YqE5QYzxyHEULB4HeUZRRFWfYte7Pl8/ni8bjb7c7BOst5KDKcgHmeD/gxhBDLsjRNcxxH0zS7vR2hIgcHSddzVw4uslRA8X/MQUEQYrHY7u5uOp1OJpOpVGp/fz+TyYweC0xvPgXz2agQQtEoR0doiqIYhqFpRpbldDrtch2Ziy1mKDaeO7EqUaQj2mt/aJVIJBKSJCmKIknS4eHhcbjUUvkvLMsSz6N4XNVCVpS9vT1FUVRVPW0uNZ0yR/kYHiZoOhIOh0mS1NLSkmMYhkmlkiMjR6symP/COp0OADo7O5CohLaIcBgnCYIkSRzHSZIM4zhF0T9/ZcbGPJop22yotEBhaXn2Y2jDWp3OAObHMD+26cf8m37/ZiCArSwvr/i8i5/XZ73fbt3uzTWXVViguqm9st5RdaXVVNdUYW2w2pqtNofV5qhpcNbYtXOpoaXW7qxtbK1vumq1OyvqGqtsTtNle7Xj2h9qUrpLqPNGPQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6f5f0f47592effbd03525ecb0df8da9/e4706/SecuritySpy_02.avif 230w", "/en/static/a6f5f0f47592effbd03525ecb0df8da9/d1af7/SecuritySpy_02.avif 460w", "/en/static/a6f5f0f47592effbd03525ecb0df8da9/c9875/SecuritySpy_02.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a6f5f0f47592effbd03525ecb0df8da9/a0b58/SecuritySpy_02.webp 230w", "/en/static/a6f5f0f47592effbd03525ecb0df8da9/bc10c/SecuritySpy_02.webp 460w", "/en/static/a6f5f0f47592effbd03525ecb0df8da9/d00b9/SecuritySpy_02.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6f5f0f47592effbd03525ecb0df8da9/81c8e/SecuritySpy_02.png 230w", "/en/static/a6f5f0f47592effbd03525ecb0df8da9/08a84/SecuritySpy_02.png 460w", "/en/static/a6f5f0f47592effbd03525ecb0df8da9/5a190/SecuritySpy_02.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a6f5f0f47592effbd03525ecb0df8da9/5a190/SecuritySpy_02.png",
              "alt": "SecuritySpy for your INSTAR IP Camera",
              "title": "SecuritySpy for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose your camera model and type in your camera´s IP address, it's ports (default: 80 & 554) and your login. Click on Ok.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/50922fa28a8302d02c76ee61d9c8470d/5a190/SecuritySpy_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsTAAALEwEAmpwYAAADiUlEQVQoz42T21MbZQDFP8apbz6oQGupjBSZNoWSkAsBxxGkTAkl3CG1pa14CQkIpFqjbaehQCAZctvNJnvNZi9JdvfbTUhCLlCdQgf7oOM444t/kIMzvvjkb87DefydhwPWIzsBLOZPIv4kso0jG1jYh4R9SGgLi+KZFKlmmX2FzEtJNZuQeULN0FqO0LJMQdrL0OBcSxMAADQ0gH9p+Ke/+04jLP+kHp0Wj3+rvv5Trh4rh7/kX7yunP5RPvn951//eo7goNVkaP/gcrfBaO7tN5qtJkt/r/Ujk8U6NGyLYBSKM0maSzI8RqZwmn/i2/Q88q4+fPSd97FjcRH4CQbKJW2/Xqocl8ovywcvK7WTw6PTw6NTRS5pWlXVqhBWIKyosJrAUqEQFgrFI+FEIIqBICPsFw4lpSzJZ8lJRV5QZLmoKGWaEjKZvJav56QSz6tQrRSKLyq1k2r9Vb3+Si7WQRDnpVxRyBSETEHMFsTsPknygqjxQn4viBJ4OieVWFZKJlLwzKKm5mtQq2pajZP2QRDjOF5N86ogaoKoidlCPE4JgqrAg71glE3lFFihKR6JJhRYVvNnK2T1AMJqWsqDXTQtihonqCwHGVbCCWEvGEtzMsfDTd8uipCCqJEkHwljUD1QYFmBJRmWFKVECzLYQVIsK9GsxLASzWTolPSj92kcpeIouepe29kKoAi+6fN7VtZjkTjHydlsQciovABRkgdbEZqkBIIS6bREUDwapzyrD2OxBIKSbuc325s7mxv+Fff6zORcOIJhCYakMyQtUpQYTabA8xBFkBxBi3E0kcTpSCy5vLQSCETCUXzZtep0rt0cne/sspiN1uWVb5NEGkswCZzFiXQ4ToKNPQIn2GAo/uypb3Hh/ud3FxZu35mddty/9+XIDZvt5tj45O2BwZEevcXhuPeD98n33mf+3djWdvixLwgGbPN91o91V/WX23RdnYbBgaG5Gcfigy+WvlpyOV3LrmX3ktuz5nE53c6v3f19A2bLJzeGJz8dsg+NTAHL4Hh3t8Vg7L+ut+gNvT3m/h5T39Cwbcw+NWafmplxzM3ftU/MjoyO28dnR29N37LP2ifmJiYd9unPwFtNl/5zjP/JG+feBK2m4ca2zgsdhqa262+36prbu893GJo/1F+8amrRmVt0pveuGFt0pkvXzO939rZcMze2dV3o0De3d56/ov8bv+a05V5N9lEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/50922fa28a8302d02c76ee61d9c8470d/e4706/SecuritySpy_03.avif 230w", "/en/static/50922fa28a8302d02c76ee61d9c8470d/d1af7/SecuritySpy_03.avif 460w", "/en/static/50922fa28a8302d02c76ee61d9c8470d/c9875/SecuritySpy_03.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/50922fa28a8302d02c76ee61d9c8470d/a0b58/SecuritySpy_03.webp 230w", "/en/static/50922fa28a8302d02c76ee61d9c8470d/bc10c/SecuritySpy_03.webp 460w", "/en/static/50922fa28a8302d02c76ee61d9c8470d/d00b9/SecuritySpy_03.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/50922fa28a8302d02c76ee61d9c8470d/81c8e/SecuritySpy_03.png 230w", "/en/static/50922fa28a8302d02c76ee61d9c8470d/08a84/SecuritySpy_03.png 460w", "/en/static/50922fa28a8302d02c76ee61d9c8470d/5a190/SecuritySpy_03.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/50922fa28a8302d02c76ee61d9c8470d/5a190/SecuritySpy_03.png",
              "alt": "SecuritySpy for your INSTAR IP Camera",
              "title": "SecuritySpy for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And Done - your surveillance is up & running.`}</p>
    <h3 {...{
      "id": "alarm-function",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alarm-function",
        "aria-label": "alarm function permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Function`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c9cdc8442a1332c3bb691611b5f33550/5a190/SecuritySpy_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.82608695652175%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAELUlEQVQ4yz2SX0wTdwDHfxBejBkPuuxlTuaMKKiwRQWBzGwxLlkW2YyZLzMhOmNA2qLL/rhJUMmAisbCKChY21LQItCWQwq0tFDK/3/t3bW967XXa+96/cNdC4KULSSEpduybz4P3+/D9+WbL3hg6H5k6hWPdP8xb2iwDd4fXvi5g3xomWqcH747qK4Z090z9VRZNFVW7QPY8NBmaFwySmzGBrtRAhtBieiGQCQqqxCViQSlwvLvS69eK79acu1KxU8/dul0ryBIo9f3Dgx0Q1Ay9kE9/f2voL4+vV7SIQfgnV0AAJAC/ldKaioAIOPgId34Ut/Y3OAMOryAac0zkHVJa57tn7BB1kXTAi5WvQBni4uLvyq+fr1MKLpVXi4SCG4KhTfLBRW/3L7T9kz+tFX2XK5SKDsVig6VSi2RNNbU1tbW1dX9XvtDdRWQqbWvIRNBkCzLRiKRIBNk2VCMj6+9WQuxrJ+i3ARB+aggE6RpJhDwU5SP9JGMPzDngIFU8bJLDREeL8ctcxyHoKjVOkEQBMMESR9FkmSM5zcTiXh85e3GxkZiI5FIbG9v7+zseIM0aGx70d7es2jHYNSJoiiKwBTpiYSCy2GWZfxv19fEYnF2dnZhYUF+fl6S0/lFRYV5J0/dE9eAxy0quVxtg512JNmcWsJMs/gU4reRsTl3dHXjr8o7lbt3p+/POLDvg4x/eX9fxrt73rv1623wuFmpUHbBMOp0Omg6gJGMDWfsOO0ko4g3Gopyi4uIRmvUQcZe7VCPRq/tG4YGzAbDpMEyCR41yZ+2qhDE4XQ4vF6SDdKkx+33kV4C9/s88ThvMU3XyhovWS9fGPn24silb8YulpqFLzt1ukEjEEuetTyR22HU5XIFAgGfLzkvTTM+n49mGI5fto7O1agkn8Nni+bP5E0UZBqPnLdckMvUmoGhZLn5yXMEcWAuF8MwXo+H8vvj8RjP8+FweGU13qns+uLy+RxF7omOU5+0n8hSHCuQfnql5EaLTAmq65vr6xsQ1IHjGAzDLqcLx93BYJBhGJZlN//crKy8m56+d//BjzKPZmfn5Bz9ODfreO6BDw//VnUfnD7zZeahY+bRMZIk7XZ7KBymKAp34x6Ph6bpra0tkUgEAEhLS0sBqf/cOCU1NQ0AUCYQgpNF5w4fOT46ZolEIhRFsSzrdrtdLgxzYX7Kz3Ncv35YLBZLm6VN0iaptKlJ2tTW1trSKlP29INd6XsAAFqtlqIoBEFgGMZxHEVQBEGcTheOYR6PlyAIwk1QFBWNLkejySOur68hmBtknftub1bRyOj4m9WVEMuGWJbnOJ7nuVh8dSW+Eo8lSZoksRjPLScVicbsTgJ8Xd37WUVLp25oenbebJmwTM5YrFOm8Wm91QajzkXYsYT8hy0ZUevk9PisXf56oVVj/RsiK8HQXrakPgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c9cdc8442a1332c3bb691611b5f33550/e4706/SecuritySpy_04.avif 230w", "/en/static/c9cdc8442a1332c3bb691611b5f33550/d1af7/SecuritySpy_04.avif 460w", "/en/static/c9cdc8442a1332c3bb691611b5f33550/c9875/SecuritySpy_04.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c9cdc8442a1332c3bb691611b5f33550/a0b58/SecuritySpy_04.webp 230w", "/en/static/c9cdc8442a1332c3bb691611b5f33550/bc10c/SecuritySpy_04.webp 460w", "/en/static/c9cdc8442a1332c3bb691611b5f33550/d00b9/SecuritySpy_04.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c9cdc8442a1332c3bb691611b5f33550/81c8e/SecuritySpy_04.png 230w", "/en/static/c9cdc8442a1332c3bb691611b5f33550/08a84/SecuritySpy_04.png 460w", "/en/static/c9cdc8442a1332c3bb691611b5f33550/5a190/SecuritySpy_04.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c9cdc8442a1332c3bb691611b5f33550/5a190/SecuritySpy_04.png",
              "alt": "SecuritySpy for your INSTAR IP Camera",
              "title": "SecuritySpy for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on Setup to configure your camera´s Motion Detection.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e78eb3b4d0d3572d4300c59ff6c0af4b/5a190/SecuritySpy_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.3913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC9klEQVQ4y5WQy08TURSHryEuNPpPsDAhxhgfCcaNiSGRKEgNa7Zq3JhIfCFYeTVFoDxrqbW0lNLpEwRK1BplAQuQMNPOTGl72ynM3DsznaE8bBU1cWFagq+4wC/n7M53ficH3G573KBreahvfahve9DRdlfXdE/XeKel6Ulft8XrsvgJa8BjCbjNXpfZO/Yi4LaOuy3j7lG/p+6FDhw4cgj8SUlJCQDgYmXVApRDi+wilOZYfpZMzJJwloKz4eRclH8/z2h6tOBEefmZ02cvVV6u0dRWVWtqNLWaa7VXrly9fuNW78Czzu7evgFT/6DJOGTpG3jW2t6ubW7Rtra1PtJeaLkNjDaX3xckKTrNpdPpNITJZJJDCCOEYrF4lI3SETqRgBAmIYTxeDy2skKzjMitNk2MgMFhl9cbjERYgecFgYeJBEWS8/Pz8XgcISQIgiiKCKG1tTVJlhASVFVV1tXvO1+evnSBfrNzxOGnwjSEkOO4ZDLJsiyEEONCeCqVWk2nU0V2d2GMeSTsbH1s9tiAwWi32b1LJPOBjIRJcpkkw+EwRYUZhonFYhzHYUkWJUkukimCMP6ayz9xWUHPoN1u90ToKBtdWV5epiiKpCiSpBiGgYlEPBZbjPIrKaRk5J8gEX/J5dsJO+jqt5qfjzJ0dCObzWQyiqIUWlEymd/G/2Q3uXl0GOgNZqPJFo6wqqpKRWRZlvbu/LdcSM7piVHQ0Wsxme00HV0vyvI+wBjv5HKdY05wv0mnbdYtLCxls9n/kPO5LocDnD1fUVZ2amp6Znt7WxTF/ScbHE5QdrK8tPTY1NT0/mWExU+5XI/DBQ4ePgoAIAgin8/vybuPlkTp75IKv5QxRt8+bRuGCXCurvF49c3J1++y66og8CLGIkY8EpG8vrWZ3dj4VZubG6qiZCRpFavy5ucumw9UNjgr6of0JicRmBrxTIy4xx3eiWHPpD3wKhAM+abf+KZf+4MhfzAUmHlLjM+M+Sefe2aGfKH6LtsPFvgDYq7jWwQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e78eb3b4d0d3572d4300c59ff6c0af4b/e4706/SecuritySpy_05.avif 230w", "/en/static/e78eb3b4d0d3572d4300c59ff6c0af4b/d1af7/SecuritySpy_05.avif 460w", "/en/static/e78eb3b4d0d3572d4300c59ff6c0af4b/c9875/SecuritySpy_05.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e78eb3b4d0d3572d4300c59ff6c0af4b/a0b58/SecuritySpy_05.webp 230w", "/en/static/e78eb3b4d0d3572d4300c59ff6c0af4b/bc10c/SecuritySpy_05.webp 460w", "/en/static/e78eb3b4d0d3572d4300c59ff6c0af4b/d00b9/SecuritySpy_05.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e78eb3b4d0d3572d4300c59ff6c0af4b/81c8e/SecuritySpy_05.png 230w", "/en/static/e78eb3b4d0d3572d4300c59ff6c0af4b/08a84/SecuritySpy_05.png 460w", "/en/static/e78eb3b4d0d3572d4300c59ff6c0af4b/5a190/SecuritySpy_05.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e78eb3b4d0d3572d4300c59ff6c0af4b/5a190/SecuritySpy_05.png",
              "alt": "SecuritySpy for your INSTAR IP Camera",
              "title": "SecuritySpy for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select if you want to record your camera´s audio stream and if you want audio above a threshold to trigger an alert.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5bdfdd7c3be2ad1a30242178bd795e40/5a190/SecuritySpy_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADRklEQVQ4y43PTW8bRRgH8FEOIITEjW/QA+FAxQEhIRD0QCtx4MIN0SgvFSDktg69AAcCtLGoFRqTNrGbqE3cCtjUbbxp4pRSl8QFtU6yL/bu7Ozu7HpfvTvrXSe4+QTI6xKI4MBPjzQjzfznmQd8+zN1/u5i6i6Vqd6Z2FwZK1XPLKD0r5XJ6trYGpXaoL8q3xzbKH75gE7X733H/zLFlTP8vUytW2BkNHFqNJn4NJkYPf1J8tSHiZGPTw8PfzRy5vPPfiouUXTxVmm1sHKbWqZ/XLq1SBdvLNMUXaRXVyfycwA88xQ4qK+vDwBw6IUXl3/ji+uba4+EO5vS0v1HyxVm6X719gOWrjDlLfmbq3nwxrFjR98++v4HA4NDJwYGhgcHR4aGThwfGEqcTH4/NXNh8uKl6cvT2bnpmdls7ko6PXH23Pi58VTq7PjJsS9ALk8VCiURIsMwbdvWNL3RMH3fD8PQMAxVVSVJ0jTdsmzTNC3TNAwDY2wbxu81BmTz1I3FFVXVgoAQQnpnRqPhuK5hGA3DcF232WwajYZpmizLcBwXRdHjTkfAMrg490P+2k0RIl3TVEVFCCmK0muIEOolHcf1mk3TdlXNsCzT87x2GG5DAWRmrl25SskylmUkQqhrOsdy8T8t23GCVosQgrEuiQKDHA4HWFXcZnMnajO98PzCoiyrGGNRFE3Tcl3XjrmuG0ZdlmUJdUGRZeI/0Y4iFgrgwqX5y7PXZQXruiYKAsbYibmu6ziOZdm+171txnzfJ6T7wk4UMaIA0pm5bG5BEKCu62rMsm2EkAS7U0MIFUX1PK8VdJFYrzMnQXB+cjabm6/VBCiKPMdLEvIJEQSB53lBEOv1OseyoggtyyJ/eRKGEKQmspmpHIRod3e3FSOEhGEYxvveut/zQFgUwetvvXP4pVfWNyqdzh9es7k/1T7yL70wL0Lw8qtH+vsPx+GO53nkf/g7/PSzzwEA1kqlvb29g2H/P3UPPG+nHYcPvfne8/2vldcre487vu8FhMTzBSQI21H0z+ppBUGrFbbCHVaUwbtfF44kpwurZb5ef7i1vclw2wz7cIuvMAhrOlI1GWsK1nslyeo2w27VUGFdul6q/gkawDhZlD6kOAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5bdfdd7c3be2ad1a30242178bd795e40/e4706/SecuritySpy_08.avif 230w", "/en/static/5bdfdd7c3be2ad1a30242178bd795e40/d1af7/SecuritySpy_08.avif 460w", "/en/static/5bdfdd7c3be2ad1a30242178bd795e40/c9875/SecuritySpy_08.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5bdfdd7c3be2ad1a30242178bd795e40/a0b58/SecuritySpy_08.webp 230w", "/en/static/5bdfdd7c3be2ad1a30242178bd795e40/bc10c/SecuritySpy_08.webp 460w", "/en/static/5bdfdd7c3be2ad1a30242178bd795e40/d00b9/SecuritySpy_08.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5bdfdd7c3be2ad1a30242178bd795e40/81c8e/SecuritySpy_08.png 230w", "/en/static/5bdfdd7c3be2ad1a30242178bd795e40/08a84/SecuritySpy_08.png 460w", "/en/static/5bdfdd7c3be2ad1a30242178bd795e40/5a190/SecuritySpy_08.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5bdfdd7c3be2ad1a30242178bd795e40/5a190/SecuritySpy_08.png",
              "alt": "SecuritySpy for your INSTAR IP Camera",
              "title": "SecuritySpy for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The alarm actions - like an `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Alarm_Notification/"
      }}>{`Email Notifications`}</a>{` (see also Email notification setup - is configured in the tab Actions.`}</p>
    <h3 {...{
      "id": "recording",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#recording",
        "aria-label": "recording permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Recording`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dfbca6abb76df63d69ab4db22a46580b/5a190/SecuritySpy_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADaUlEQVQ4y2WS72/bRBjHT5UmIV7zD4CEyhskpAkmBhQJ3iKYFBFpewvSpE2wgcqmlk2lYUwd3Ui3pWmX0gUK649Q2qTJaKv1B2MdJU7S2I7P9tk+nx3b59jpsoa/ANkZKMBHp3txus89d997wMj6wsj6wldrc2PFtatM/kKBOTutjGw+iDOrQ2sLl+/nvthcHPotO7STGxW2vuE3E9zWdT4YN4VtcHrw3GefD/RfHOy/OPjphYGPzp/9eODMqf4z54eHflxanM0uLRRW5vLZ2dzynezPs7ml+ZXlO7mlxbsr12amADjUA/5NT0+w8uzzvfmHfOFBZZ2B9yoot83kd6orv5Z+2eULO9X7LI6lboNX+vreeO31Y8ci0ejxSCT6fvR4NHoiEol+8OHJ+I3xa/GbiYmp5K3pRDI1mUqPXBkdjn05HLsUG750auAcmEzPZTJ5jhMw1gkxVFXTNGxZtu/5BiEKUkQoqoqq6wRjrCoKkmVJFFWk7FbLIDWTyWQKkqw0XNdtNHQ92FE3SK1WYximXCpVKhWsaYZBLNt2HIdS2mq1/my3EcFgfGr2u5mfREkxDKIGKKIolVmhyvKapukhdqh1oJQ6lPqex8kiGEvOfDs9L0kKhLBYZMpMiRXQLmpVa4hnWY7nEVIMw8QYm6ZJdKJj4jhO0/d5WQTXJ75Pp+dlWVEUVC6V9/b2FFU9OHhsmqYaBIDDWdVxcAUtpCPXZAnEE7dvpX6AUPY9j+f5vUqFKRYRUjzPC0N4gtcFdd2gsiSB0bGp5EQaQsn1mn8wlRrPQwhZloUQSpIcgiilnbNc16UhTyp/HU8lktNQlBuuKwjQNE3btgJDRpZl2bZNCNE0zTRN9286siDL4PLV8bEbkzwvqKrKshzHchzHdeIRBMGybdM0DWJY9fp/ZUkCb779zuHDr25sbOk6ZllWEKAgCOGFEcaYUkcSxXq93mg0umW/I7/0cl9v74sbm1uEkHK5LIRACDVN85tNSmnQVSh49v9kGRx66mkAQC673G63wwfbTtAGjtdwm82m7/udhN0uKKX7TR/KMnju6LvPvHDk7vq9dvvADhsoDNR1HNot/PNnjUB2KfU4iMB7scW3PplY3f7drtdVVdV1HWOMNCLo7sHj1v7+o248z8Oahk13tWQVHkp/ATJPNqM94lyGAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dfbca6abb76df63d69ab4db22a46580b/e4706/SecuritySpy_06.avif 230w", "/en/static/dfbca6abb76df63d69ab4db22a46580b/d1af7/SecuritySpy_06.avif 460w", "/en/static/dfbca6abb76df63d69ab4db22a46580b/c9875/SecuritySpy_06.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dfbca6abb76df63d69ab4db22a46580b/a0b58/SecuritySpy_06.webp 230w", "/en/static/dfbca6abb76df63d69ab4db22a46580b/bc10c/SecuritySpy_06.webp 460w", "/en/static/dfbca6abb76df63d69ab4db22a46580b/d00b9/SecuritySpy_06.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dfbca6abb76df63d69ab4db22a46580b/81c8e/SecuritySpy_06.png 230w", "/en/static/dfbca6abb76df63d69ab4db22a46580b/08a84/SecuritySpy_06.png 460w", "/en/static/dfbca6abb76df63d69ab4db22a46580b/5a190/SecuritySpy_06.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dfbca6abb76df63d69ab4db22a46580b/5a190/SecuritySpy_06.png",
              "alt": "SecuritySpy for your INSTAR IP Camera",
              "title": "SecuritySpy for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Use snapshots to create a Timelapse video. Or upload snapshots to an `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/FTP_Server_Setup/"
      }}>{`FTP SERVER`}</a>{` or your `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Router_as_a_FTP_Server/"
      }}>{`Router`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/27fa78dc6a8b446a0843bfa0e4af7b96/5a190/SecuritySpy_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADUUlEQVQ4y3WTT08bRxTAV1a/Qw/9Au2hqDmkStIe2hwqVWpLoEo/QCtVvVT9SxMVkTg0NFERodRgDHFciKEFA8HGKImDBMG4qNjs2uvdndmZnZld27uzu4YawyeobFoO0P4OI43e+817ozcj/LS2cPfp3EBmbqSQGdpd/T4pfRE1Bze2fi48CWYSd7IrwfWlm1up4HZ6CGwMK+tj5echZSOkboyCTeGbG73XbvZdu3Xju2BfT7Dvy96er/p6Pr/+be+Ptx8uJmaWF39PJ2dTj2aSS/HlhZnk0m+ppXhyMZFODU5PCmcJBAKCILzSce5pHqSz4loBroloZbOw+kcpvSk++VN9vC1vyexWJCqcv3Dh0sVL771/pav7aueVD7u6rnZ3f9TZ2fXxJ5+OjE4MDYfGIg/CE7HR8ej4ZOzO3cFg/+1g/8AP/QOfXe8RYtOJ+fkVuawSQhljhkEIobWa7TgcY6ypmlySMcImMymlCCEIgKapGMKcmBemZ5cXFh9jbLice65LKcHYYIxxzi3LrFQqlmUpiiJJkiyXTMZ8z2s0GkeHh8ikwv3YXHzmkaIhbNC2iQlGBjZ0CCkljuNUq1XDMBBCGONKpWLbtuM4dd8vQU0Yi8Rjv84jTMqKks/nVQBzBXVXLEqiKElSrVbzPM/3/Xobrw3nfK9elyEQwhPxqYcJjAljVFUUzKq7xr6i6RjplDLbtt0zHMtlHQih8NT96CxChmEYoihSSpqNfU3VoK7X6z7n3GnDOT8lywAI936JRiandYR1YpbLCqVUb9E+DGPP8yzLQu3tiX8sKzoU7o1MhiNTOsIAgFKppGkahFBRVEmSdnZ2AACEkGq16nneqcoteXB4PBR+AKDOGC0Wi6Zp7u3tQQghANVK1bIsxlitVjt7ZwUA4a3L7775xuVcbptzXpSKhFBCDAAA0hGl1PM80zRb8yPkdNsQCh3nLr7acT6bzR0cHLiu25qE63r/Jp3gOM6pyiqErW/wQiDwLJNpNpu2bf+T6/4vx/F221B46bW3X3z59fXn2aOjQ9d1/daDaC2cu95/4fu+67p1v64AXfggmHjn67Fnm9smaw0JY2xgpOlEZW7zoLG//9cJjUbD830DIcPiq/lKKgf+BobBOoW1wm8sAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/27fa78dc6a8b446a0843bfa0e4af7b96/e4706/SecuritySpy_07.avif 230w", "/en/static/27fa78dc6a8b446a0843bfa0e4af7b96/d1af7/SecuritySpy_07.avif 460w", "/en/static/27fa78dc6a8b446a0843bfa0e4af7b96/c9875/SecuritySpy_07.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/27fa78dc6a8b446a0843bfa0e4af7b96/a0b58/SecuritySpy_07.webp 230w", "/en/static/27fa78dc6a8b446a0843bfa0e4af7b96/bc10c/SecuritySpy_07.webp 460w", "/en/static/27fa78dc6a8b446a0843bfa0e4af7b96/d00b9/SecuritySpy_07.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/27fa78dc6a8b446a0843bfa0e4af7b96/81c8e/SecuritySpy_07.png 230w", "/en/static/27fa78dc6a8b446a0843bfa0e4af7b96/08a84/SecuritySpy_07.png 460w", "/en/static/27fa78dc6a8b446a0843bfa0e4af7b96/5a190/SecuritySpy_07.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/27fa78dc6a8b446a0843bfa0e4af7b96/5a190/SecuritySpy_07.png",
              "alt": "SecuritySpy for your INSTAR IP Camera",
              "title": "SecuritySpy for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Configure the video recording function and the `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Router_as_a_FTP_Server/"
      }}>{`FTP Upload`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      